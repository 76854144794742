<template>
	<div class="top-bar fix-on-scroll">
		<div class="row">
			<div class="columns column12">
				<a :href="`mailto:${email}`">
					<font-awesome-icon class="icon" icon="fa-light fa-envelope" />{{ email }}
				</a>
				<span class="divider" />
				<a :href="`tel:${phone}`" class="border-link" style="border-right: none">
					<font-awesome-icon class="icon" icon="fal fa-phone" />
					{{ phone }}
				</a>
				<span class="divider" />

				<div v-click-outside="closeLangNav" class="nav-buttons">
					<div v-if="navigation.length" class="lang-select" @click="toggleLangNav">
						<font-awesome-icon icon="fa-light fa-globe" size="1x" />
						<span>{{ locale }}&nbsp;</span>
						<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
						<div v-if="showLangNav" class="lang-wrapper">
							<div v-for="item in navigation" :key="`${item.filename}-${item.language}`">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									{{ item.language }}
								</nuxt-link>
							</div>
						</div>
					</div>
				</div>
				<div class="border" />
			</div>
		</div>
	</div>
</template>

<script setup>
defineProps({
	phone: { type: String, default: '' },
	email: { type: String, default: '' },
	navigation: { type: Array, default: () => [] },
});

const { locale } = useI18n();

const showLangNav = ref(false);

const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};

const closeLangNav = () => {
	showLangNav.value = false;
};
</script>

<style lang="scss" scoped>
.top-bar {
	text-align: right;
	padding: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1001;
	transition: background 0.3s ease-in-out;
	font-size: calc(var(--body-font-size) - 3px);
	font-weight: 400;

	a {
		color: #fff;
		text-decoration: none;

		&.border-link {
			border-left: 1px solid #fff;
			border-right: 1px solid #fff;
			padding: 0 20px;
			margin: 0 20px;
		}
	}

	.lang-nav {
		box-shadow: none;
		cursor: pointer;
		appearance: menulist;
		background: none;
		min-width: 34px;
		color: #fff;
		border: none;
		text-transform: uppercase;
		font-size: 12px;
		font-family: sans-serif;

		option {
			color: #000;
		}
	}

	.icon {
		margin-right: 5px;
		color: white;
	}

	.border {
		width: 100%;
		margin: 12px 0 0;
		height: 1px;
	}
}

.nav-buttons {
	display: inline-block;
}

.lang-select {
	padding: 12px 15px;
	border-right: 1px solid var(--alternating-background-color);
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	color: #fff;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	i,
	svg {
		font-size: 13px;

		&:first-child {
			margin: 0 7px 0 0;
		}
	}

	a {
		color: #000;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		place-content: center flex-start;
		gap: 8px;
		margin: 7px 0;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 5px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	.flag::before {
		content: '';
		width: 16px;
		position: relative;
		top: 0;
		height: 16px;
		display: inline-block;
	}

	.flag.en::before {
		background: url('~/assets/images/en.png') no-repeat;
	}

	.flag.nl::before {
		background: url('~/assets/images/nl.png') no-repeat;
	}

	.flag.de::before {
		background: url('~/assets/images/de.png') no-repeat;
	}

	.flag.fr::before {
		background: url('~/assets/images/fr.png') no-repeat;
	}

	.flag.es::before {
		background: url('~/assets/images/es.png') no-repeat;
	}

	.flag.no::before {
		background: url('~/assets/images/no.png') no-repeat;
	}
}

@media only screen and (max-width: 1025px) {
	.top-bar .lang-nav {
		appearance: none;
		background: url('~/assets/images/dropdown.png') right 5px center no-repeat;
	}
}

@media (max-width: 1280px) {
	.top-bar {
		display: none;
	}
}
</style>
